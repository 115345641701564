export default Object.freeze([
  {
    id: 'qr1tynmqi',
    spacing: {
      margin: '0',
      padding: '0',
    },
    verticalAlignment: 'MIDDLE',
    heroEffect: {},
    columns: [
      {
        id: 'unkn2hl23',
        colspan: {
          sm: 3,
          md: 3,
          lg: 3,
        },
        weight: 3,
        contentBlocks: [],
      },
      {
        id: '2qyvr0h7a',
        background: {
          color: '#FFF',
        },
        attributes: {
          className: 'Grid_align-self-center',
        },
        padding: '26px',
        colspan: {
          sm: 6,
          md: 6,
          lg: 6,
        },
        weight: 6,
        contentBlocks: [
          {
            id: '99pic29gj',
            name: 'Logo',
            spacing: {
              padding: '0 0 14px',
            },
          },
          {
            id: 'ba8np721v',
            name: 'SanaText',
            model: {
              key: 'ClosedStore_Login_Header',
              styleMode: 'Heading1',
            },
            spacing: {
              padding: '0 0 7px',
            },
          },
          {
            id: 'ew56dwpll',
            name: 'SanaText',

            model: {
              key: 'ClosedStore_Login_Description',
              styleMode: 'None',
            },
            spacing: {
              padding: '0 0 20px',
            },
          },
          {
            id: 'dhct2kiwu',
            name: 'LoginForm',
          },
        ],
      },
      {
        id: 'll0jg6vnh',
        colspan: {
          sm: 3,
          md: 3,
          lg: 3,
        },
        weight: 3,
        contentBlocks: [],
      },
    ],
  },
]);
