export default Object.freeze([
  {
    id: '9g6sf1sth',
    spacing: {
      margin: '0',
      padding: '0',
    },
    verticalAlignment: 'MIDDLE',
    heroEffect: {},
    columns: [
      {
        id: '0hrz6dgsg',
        colspan: {
          sm: 3,
          md: 3,
          lg: 3,
        },
        weight: 3,
        contentBlocks: [],
      },
      {
        id: 'gkz0mr26i',
        background: {
          color: '#FFF',
        },
        padding: '26px 26px 20px',
        attributes: {
          className: 'Grid_align-self-center',
        },
        colspan: {
          sm: 6,
          md: 6,
          lg: 6,
        },
        weight: 6,
        contentBlocks: [
          {
            id: 'rtgy9q05o',
            name: 'Logo',
            spacing: {
              padding: '0 0 14px',
            },
          },
          {
            id: 'kcetu4ps4',
            name: 'SanaText',
            model: {
              key: 'ForgotPassword',
              styleMode: 'Heading1',
            },
            spacing: {
              margin: '9px 0',
            },
          },
          {
            id: 'ganu6p09f',
            name: 'SanaText',
            model: {
              key: 'ForgotPassword_Intro',
              styleMode: 'None',
            },
          },
          {
            id: 'o47ldlgnq',
            name: 'ForgotPasswordForm',
          },
        ],
      },
      {
        id: 'xv70mxpkn',
        colspan: {
          sm: 3,
          md: 3,
          lg: 3,
        },
        weight: 3,
        contentBlocks: [],
      },
    ],
  },
]);
