export default Object.freeze([
  {
    id: 't4z14f1bk',
    spacing: {
      margin: '0',
      padding: '0',
    },
    verticalAlignment: 'MIDDLE',
    heroEffect: {},
    columns: [
      {
        id: '5vvkiifgz',
        background: {
          color: '#FFF',
        },
        attributes: {
          className: 'Grid_align-self-center',
        },
        padding: '26px',
        contentBlocks: [
          {
            id: 'tnsot7xhk',
            name: 'Logo',
            spacing: {
              padding: '0 0 14px',
            },
          },
          {
            id: 'vkeh3lihq',
            name: 'SanaText',
            model: {
              key: 'ClosedStore_Login_Header',
              styleMode: 'Heading1',
            },
            spacing: {
              padding: '0 0 6px',
            },
          },
          {
            id: 'onjigltd0',
            name: 'SanaText',
            model: {
              key: 'ClosedStore_Login_Description',
              styleMode: 'None',
            },
            spacing: {
              padding: '0 0 20px',
            },
          },
          {
            id: 'gfy9e8usk',
            name: 'LoginForm',
          },
        ],
      },
    ],
  },
]);
