import type { RowContentElement } from 'behavior/content';
import { useVerticalOverflowFix } from 'utils/hooks';
import PageDocumentTitle from './PageDocumentTitle';
import { Media } from 'components/responsive';
import MobileTemplate from './MobileTemplate';
import DesktopTemplate from './DesktopTemplate';

type Props = {
  desktop: RowContentElement[] | null;
  mobile: RowContentElement[] | null;
};

const ClosedStoreLogin = ({ desktop, mobile }: Props) => {
  useVerticalOverflowFix();

  return (
    <>
      <PageDocumentTitle />
      <Media lessThan="md">
        <MobileTemplate mobile={mobile} />
      </Media>
      <Media greaterThanOrEqual="md">
        <DesktopTemplate desktop={desktop} />
      </Media>
    </>
  );
};

export default ClosedStoreLogin;
