import type { RowContentElement } from 'behavior/content';
import { memo } from 'react';
import { renderRows, extendDefaultSettings } from 'components/containers';
import { mobile as defaultMobile } from './defaultContent';

type Props = {
  mobile: RowContentElement[] | null;
};

const MobileTemplate = ({ mobile }: Props) => renderRows(mobile ?? extendDefaultSettings(defaultMobile));

export default memo(MobileTemplate);
