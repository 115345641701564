export default Object.freeze([
  {
    id: '5dv7wcaqf',
    spacing: {
      margin: '0',
      padding: '0',
    },
    verticalAlignment: 'MIDDLE',
    heroEffect: {},
    columns: [
      {
        id: 'z8ap40lnv',
        colspan: {
          sm: 3,
          md: 3,
          lg: 3,
        },
        weight: 3,
        contentBlocks: [],
      },
      {
        id: '20akfiqoe',
        colspan: {
          sm: 6,
          md: 6,
          lg: 6,
        },
        weight: 6,
        background: {
          color: '#FFF',
        },
        padding: '26px 26px 20px',
        attributes: {
          className: 'Grid_align-self-center',
        },
        contentBlocks: [
          {
            id: 'ibxxym1fk',
            name: 'Logo',
            spacing: {
              padding: '0 0 14px',
            },
          },
          {
            id: 'aw4moewrh',
            name: 'SanaText',
            model: {
              key: 'ChangePassword',
              styleMode: 'Heading1',
            },
            spacing: {
              margin: '9px 0',
            },
          },
          {
            id: '8xloobdil',
            name: 'SanaText',
            model: {
              key: 'ChangePassword_Description',
              styleMode: 'None',
            },
          },
          {
            id: 'xnxico0kw',
            name: 'ResetPasswordForm',
            spaicing: {
              margin: '13px 0 20px',
            },
          },
        ],
      },
      {
        id: 'qpl9n3o15',
        colspan: {
          sm: 3,
          md: 3,
          lg: 3,
        },
        weight: 3,
        contentBlocks: [],
      },
    ],
  },
]);
